import React from 'react'

import Container from '@material-ui/core/Container'
import { useDispatch, useSelector } from 'react-redux'
import { Paper } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import useStyles from '../utils/styles'
import Layout from '../components/Layout'
import Footer from '../components/footer/footer'


class Terms extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLogin: true,
      email: '',
    }
  }

  componentDidMount() {
    const { dispatch, userData, basicData } = this.props
  }

  onSignup = () => {}

  onSignin = () => {}

  handleChange = event => {
    this.setState({ email: event.target.value })
  }

  
  render() {
    const { userData, classes } = this.props
    const sections = [
      "OUR SERVICES",
      "INTELLECTUAL PROPERTY RIGHTS",
      "USER REPRESENTATIONS",
      "USER REGISTRATION",
      "PURCHASES AND PAYMENT",
      "SOFTWARE",
      "PROHIBITED ACTIVITIES",
      "USER GENERATED CONTRIBUTIONS",
      "CONTRIBUTION LICENSE",
      "SERVICES MANAGEMENT",
      "PRIVACY POLICY",
      "COPYRIGHT INFRINGEMENTS",
      "TERM AND TERMINATION",
      "MODIFICATIONS AND INTERRUPTIONS",
      "GOVERNING LAW",
      "DISPUTE RESOLUTION",
      "CORRECTIONS",
      "DISCLAIMER",
      "LIMITATIONS OF LIABILITY",
      "INDEMNIFICATION",
      "USER DATA",
      "ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES",
      "CALIFORNIA USERS AND RESIDENTS",
      "MISCELLANEOUS",
      "CONTACT US"
    ];
    const serviceItems = [
      "access the Services; and",
      "download or print a copy of any portion of the Content to which you have properly gained access."
    ];
    const creditCardItems = [
      "Visa",
      "Mastercard",
      "Discover",
      "American Express"
    ];
    const prohibitedItems = [
      "Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.",
      "Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.",
      "Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services and/or the Content contained therein.",
      "Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.",
      "Use any information obtained from the Services in order to harass, abuse, or harm another person.",
      "Make improper use of our support services or submit false reports of abuse or misconduct.",
      "Use the Services in a manner inconsistent with any applicable laws or regulations.",
      "Engage in unauthorized framing of or linking to the Services.",
      "Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Services.",
      "Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.",
      "Delete the copyright or other proprietary rights notice from any Content.",
      "Attempt to impersonate another user or person or use the username of another user.",
      "Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats ('gifs'), 1×1pixels, web bugs, cookies, or other similar devices (sometimes referred to as 'spyware' or 'passive collection mechanisms' or 'pcms').",
      "Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the Services.",
      "Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to you.",
      "Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services.",
      "Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.",
      "Except as permitted by applicable law, decipher, decompile, disassemble, or reverse enginee",
      "Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Services, or use or launch any unauthorized script or other software.",
      "Use a buying agent or purchasing agent to make purchases on the Services.",    
      "Make any unauthorized use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.    ",
      "Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for any revenue-generating endeavor or commercial enterprise.",
      "Sell or otherwise transfer your profile."
    ];
  
    return (
      <Layout  menuIndex={this.props.menuIndex} loader={this.state.showLoader}>
        <Container maxWidth="md" className={classes.rootContainer}>
          <Paper elevation={0}>
            <div style={{ marginTop: 40 }}>
              <div className={classes.aboutTermScrollRoot}>
                <Typography
                  variant="body1"
                  align="center" 
                  gutterBottom
                  style={{ marginBottom: 30, maxHeight: '50vh', textAlign: 'justify', textJustify: 'inter-word' }}
                  component="div"               
                >
                  <Typography variant="h4" component="div" style={{ marginBottom: '16px'}}>
                    <strong>Terms & Conditions</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    Last updated June 18, 2024   
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    <strong> AGREEMENT TO OUR LEGAL TERMS </strong>
                  </Typography>                 
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    We are Veritec Inc., doing business as Veritec ("Company," "we," "us," "our"), a company registered in Minnesota, United States at 2445 Winnetka Ave N, Golden Valley, MN 55427. 
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                   We operate the website idcard.veritecinc.com (the "Site"), as well as any other related products and services that refer or link to these legal terms (the "Legal Terms") (collectively, the "Services").   
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                   The "Veritec ID Card Program" is an advanced software solution designed for robust identity verification and management. It comprises a highly customizable dashboard accessible through its website and a mobile application named "Veritec Card." This program leverages Veritec's patented VeriCode® technology, allowing secure encoding and scanning of sensitive information directly onto ID cards. Users can access and manage data such as personal details and financial information securely and offline, enhancing both convenience and security.  
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                   This software is engineered to support various user roles, each with specific functionalities: Veritec Admins can manage system settings and user permissions, Program Managers oversee organizational data, Users handle day-to-day operations, and Cardholders access their own encrypted data through VeriCodes®. Ideal for organizations looking to streamline their identification processes, the Veritec ID Card Program offers a scalable, secure, and user-friendly platform for managing sensitive data effectively. 
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you"), and Veritec Inc., concerning your access to and use of the Services. You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                  </Typography>                     
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}} >
                    We reserve the right to modify these Legal Terms at any time and for any reason at our sole discretion. The modified Legal Terms will become effective 7 days after they are posted or as stated in an email notification sent to you, whichever occurs first. We are not obligated to notify you of any changes unless they are deemed significant.   
                  </Typography>                     
                  <Typography variant="body1" component="div"  style={{ marginBottom: '16px'}}>
                    You are advised to review the updated Legal Terms periodically and print a copy of these Legal Terms for your records. 
                  </Typography>                                    
                  <Typography variant="h6" component="div" style={{ marginBottom: '16px'}}>
                    TABLE OF CONTENTS
                  </Typography>    
                    <Typography component="div" style={{ marginBottom: '16px'}}>
                    <ol style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                      {sections.map((section, index) => (
                        <li key={index}>{`${index + 1}. ${section}`}</li>
                      ))}
                    </ol>
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                    <strong>1. OUR SERVICES</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.   
                  </Typography>  
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    The Services are not tailored to comply with industry-specific regulations (Health Insurance Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions would be subjected to such laws, you may not use the Services. You may not use the Services in a way that would violate the Gramm-Leach-Bliley Act (GLBA).
                  </Typography>  
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                    <strong>2. INTELLECTUAL PROPERTY RIGHTS </strong>
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                    <strong>Our intellectual property </strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services (collectively, the "Content"), as well as the trademarks, service marks, and logos contained therein (the "Marks").
                  </Typography> 
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws) and treaties in the United States and around the world.
                  </Typography> 
                  <Typography variant="body1" component="div"style={{ marginBottom: '16px'}}>
                    The Content and Marks are provided through the Services solely for your personal non-commercial use or internal business purposes. You are prohibited from copying, reproducing, republishing, uploading, posting, publicly displaying, encoding, translating, transmitting, distributing, selling, licensing, or otherwise commercially exploiting any Content or Marks without our express prior written permission. Any unauthorized use of our Content or Marks constitutes a violation of our intellectual property rights and may result in legal action.
                  </Typography>  
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                    <strong>Your use of our Services </strong>
                  </Typography>              
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    Subject to your compliance with these Legal Terms, including the "PROHIBITED ACTIVITIES" section below, we grant you a non-exclusive, non-transferable, revocable license to:
                  </Typography>  
                  <Typography variant="body1" component="div">
                  <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    {serviceItems.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                  </Typography>  
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    Solely for your personal, non-commercial use or internal business purpose.                   </Typography> 
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
                  </Typography> 
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>  
                    If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please address your request to: info@veritecinc.com. If we ever grant you the permission to post, reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on posting, reproducing, or displaying our Content.
                  </Typography> 
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>  
                    We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.
                  </Typography> 
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>  
                    Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your right to use our Services will terminate immediately.
                  </Typography>              
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                    <strong>Copyright infringement</strong>
                  </Typography>     
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                   We respect the intellectual property rights of others. If you believe that any material available on or through the Services infringes upon any copyright you own or control, please immediately refer to the "COPYRIGHT INFRINGEMENTS" section below.
                  </Typography>  
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                    <strong>3. USER REPRESENTATIONS </strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                   By using the Services, you represent and warrant that: (1) all registration information you submit will be true, accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update such registration information as necessary; (3) you have the legal capacity, and you agree to comply with these Legal Terms; (4) you are not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission to use the Services; (5) you will not access the Services through automated or non-human means, whether through a bot, script or otherwise; (6) you will not use the Services for any illegal or unauthorized purpose; and (7) your use of the Services will not violate any applicable law or regulation.
                  </Typography>  
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof).
                  </Typography>  
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                    <strong>4. USER REGISTRATION</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    You may be required to register to use the Services. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.
                  </Typography>  
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                    <strong>5. PURCHASES AND PAYMENT</strong>
                  </Typography>
                  <Typography variant="body1" component="div">
                    All purchases are non-refundable. 
                  </Typography> 
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    We accept the following forms of payment:
                  </Typography>                    
                  <Typography variant="body1" component="div">
                  <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    {creditCardItems.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                  </Typography>  
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Services. You further agree to promptly update account and payment information, including email address, payment method, and payment card expiration date, so that we can complete your transactions and contact you as needed. Sales tax will be added to the price of purchases as deemed required by us. We may change prices at any time. All payments shall be in US dollars.
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping fees, and you authorize us to charge your chosen payment provider for any such amounts upon placing your order. We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment.
                  </Typography>
                  <Typography variant="body1" component="div"style={{ marginBottom: '16px'}}>
                    We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit or cancel quantities purchased per person, per household, or per order. These restrictions may include orders placed by or under the same customer account, the same payment method, and/or orders that use the same billing or shipping address. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers, or distributors.
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                    <strong>6. SOFTWARE</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                   We may include software for use in connection with our Services. If such software is accompanied by an end user license agreement ("EULA"), the terms of the EULA will govern your use of the software. If such software is not accompanied by a EULA, then we grant to you a non-exclusive, revocable, personal, and non-transferable license to use such software solely in connection with our services and in accordance with these Legal Terms. Any software and any related documentation is provided "AS IS" without warranty of any kind, either express or implied, including, without limitation, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. You accept any and all risk arising out of use or performance of any software. You may not reproduce or redistribute any software except in accordance with the EULA or these Legal Terms.
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                    <strong>7. PROHIBITED ACTIVITIES</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                   We may include software for use in connection with our Services. If such software is accompanied by an end user license agreement ("EULA"), the terms of the EULA will govern your use of the software. If such software is not accompanied by a EULA, then we grant to you a non-exclusive, revocable, personal, and non-transferable license to use such software solely in connection with our services and in accordance with these Legal Terms. Any software and any related documentation is provided "AS IS" without warranty of any kind, either express or implied, including, without limitation, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. You accept any and all risk arising out of use or performance of any software. You may not reproduce or redistribute any software except in accordance with the EULA or these Legal Terms.
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                  <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                    {prohibitedItems.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                  </Typography>  
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                    <strong>8. SERVICES MANAGEMENT</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal Terms; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the proper functioning of the Services.
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                  <strong>9. PRIVACY POLICY</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    We care about data privacy and security. By using the Services, you agree to be bound by our Privacy Policy posted on the Services, which is incorporated into these Legal Terms. Please be advised the Services are hosted in the United States. If you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in the United States, then through your continued use of the Services, you are transferring your data to the United States, and you expressly consent to have your data transferred to and processed in the United States.
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                  <strong>10. COPYRIGHT INFRINGEMENTS</strong>
                  </Typography>
                 <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    We respect the intellectual property rights of others. If you believe that any material available on or through the Services infringes upon any copyright you own or control, please immediately notify us using the contact information provided below (a "Notification"). A copy of your Notification will be sent to the person who posted or stored the material addressed in the Notification. Please be advised that pursuant to applicable law you may be held liable for damages if you make material misrepresentations in a Notification. Thus, if you are not sure that material located on or linked to by the Services infringes your copyright, you should consider first contacting an attorney.
                  </Typography>                  
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                  <strong>11. TERM AND TERMINATION</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                   These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICEOR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGALTERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANYTIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                  </Typography>    
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
                  </Typography>   
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                  <strong>12. MODIFICATIONS AND INTERRUPTIONS</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Services. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Services.
                  </Typography> 
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    We cannot guarantee the Services will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Services at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Services during any downtime or discontinuance of the Services. Nothing in these Legal Terms will be construed to obligate us to maintain and support the Services or to supply any corrections, updates, or releases in connection therewith.
                  </Typography>  
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                  <strong>13. GOVERNING LAW</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    These Legal Terms and your use of the Services are governed by and construed in accordance with the laws of the State of Minnesota applicable to agreements made and to be entirely performed within the State of Minnesota, without regard to its conflict of law principles.
                  </Typography> 
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                  <strong>14. DISPUTE RESOLUTION</strong>
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                  <strong>Informal Negotiations</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    To expedite resolution and control the cost of any dispute, controversy, or claim related to these Legal Terms (each a "Dispute" and collectively, the "Disputes") brought by either you or us (individually, a "Party" and collectively, the "Parties"), the Parties agree to first attempt to negotiate any Dispute (except those Disputes expressly provided below) informally for at least thirty (30) days before initiating arbitration. Such informal negotiations commence upon written notice from one Party to the other Party. 
                  </Typography> 
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                  <strong>Binding Arbitration</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    If the Parties are unable to resolve a Dispute through informal negotiations, the Dispute (except those Disputes expressly excluded below) will be finally and exclusively resolved by binding arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The arbitration shall be commenced and conducted under the Commercial Arbitration Rules of the American Arbitration Association ("AAA") and, where appropriate, the AAA’s Supplementary Procedures for Consumer Related Disputes ("AAA Consumer Rules"), both of which are available at the American Arbitration Association (AAA) website. Your arbitration fees and your share of arbitrator compensation shall be governed by the AAA Consumer Rules and, where appropriate, limited by the AAA Consumer Rules. The arbitration may be conducted in person, through the submission of documents, by phone, or online. The arbitrator will make a decision in writing, but need not provide a statement of reasons unless requested by either Party. The arbitrator must follow applicable law, and any award may be challenged if the arbitrator fails to do so. Except where otherwise required by the applicable AAA rules or applicable law, the arbitration will take place in United States, Minnesota. Except as otherwise provided herein, the Parties may litigate in court to compel arbitration, stay proceedings pending arbitration, or to confirm, modify, vacate, or enter judgment on the award entered by the arbitrator.
                  </Typography> 
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute shall be commenced or prosecuted in the state and federal courts located in United States, Minnesota, and the Parties hereby consent to, and waive all defenses of lack of personal jurisdiction, and forum non conveniens with respect to venue and jurisdiction in such state and federal courts. Application of the United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transaction Act (UCITA) are excluded from these Legal Terms.
                  </Typography> 
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    In no event shall any Dispute brought by either Party related in any way to the Services be commenced more than one (1) years after the cause of action arose. If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.
                  </Typography> 
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                  <strong>Restrictions</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilize class action procedures; and (c) there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the general public or any other persons.
                  </Typography> 
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                  <strong>Exceptions to Informal Negotiations and Arbitration</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    The Parties agree that the following Disputes are not subject to the above provisions concerning informal negotiations binding arbitration: (a) any Disputes seeking to enforce or protect, or concerning the validity of, any of the intellectual property rights of a Party; (b) any Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim for injunctive relief. If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.
                  </Typography> 
                  <Typography component="div" style={{ marginBottom: '16px'}}>
                  <strong>15. CORRECTIONS</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Services at any time, without prior notice.
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>                  
                  <strong>16. DISCLAIMER</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                   THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>                  
                  <strong>17. LIMITATIONS OF LIABILITY</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                   IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING OR $1,000.00 USD. CERTAIN U.S. STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>                    
                  <strong>18. INDEMNIFICATION</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) your Contributions; (2) use of the Services; (3) breach of these Legal Terms; (4) any breach of your representations and warranties set forth in these Legal Terms; (5) your violation of the rights of a third party, including but not limited to intellectual property rights; or (6) any overt harmful act toward any other user of the Services with whom you connected via the Services. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>                    
                  <strong>19. USER DATA</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, as well as data relating to your use of the Services. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Services. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>                      
                  <strong>20. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    Visiting the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Services, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>                      
                  <strong>21. CALIFORNIA USERS AND RESIDENTS</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>                      
                  <strong>22. MISCELLANEOUS</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Legal Terms is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Legal Terms and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Legal Terms or use of the Services. You agree that these Legal Terms will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Legal Terms and the lack of signing by the parties hereto to execute these Legal Terms.
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>                      
                  <strong>23. CONTACT US</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at:
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>                      
                  <strong>Veritec Inc.</strong>
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>                      
                  <strong>2445 Winnetka Ave N</strong>
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>                      
                  <strong>Golden Valley, MN 55427</strong>
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>                      
                  <strong>United States</strong>
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>                      
                  <strong>Phone: (612) 991-7928 </strong>
                  </Typography>
                  <Typography component="div" style={{ marginBottom: '16px'}}>                      
                  <strong>support@veritecinc.com</strong>
                  </Typography>
                </Typography>
              </div>            
            </div>
          </Paper>
        </Container>
        <Footer/>
      </Layout>
    )
  }
}

export default function(props) {
  const dispatch = useDispatch()
  const userData = useSelector(state => state.app.userData)
  const classes = useStyles()

  return (
    <Terms
      {...props}
      menuIndex={0}
      dispatch={dispatch}
      userData={userData}
      classes={classes}
    />
  )
}
